import { Button, CircularProgress, Dialog, DialogActions, DialogTitle, Grid, MenuItem, TextField, LinearProgress } from '@material-ui/core';
import React from 'react';
import Counters from '../counters/counters';
import Table from '../table/table';
import utils from './utils';
import axios from 'axios';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DatePicker } from "@material-ui/pickers"
import { connect } from "react-redux";
import WhiteTextField from '../form-fields/WhiteTextField';
import config from '../../../../config';
import { ToastContainer, toast } from 'react-toastify'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import xlsx from "xlsx";
import { format as dateFormat } from "date-fns";

function Home(props) {

    const [state, setState] = React.useState(utils.defaultState);
    const [userConfig, setUserConfig] = React.useState({})
   
    const changeHandler = (keys, values) => {
        setState(state => {
            const newState = {...state}
            keys.forEach((element, index) => {
                newState[element] = values[index]
            });
            return newState;
        });
    }
    const fetchVendorList = () => {
        axios.get(config.apiUrl+"vendors")
        .then(res => {
            if(res?.data?.result?.length){
                let vindex = utils.newInvoice.findIndex(field=> field.key == 'vendor_name')
                if(vindex > -1)
                    utils.newInvoice[vindex].options = res?.data?.result.map(m=> m.name)
            }
           
        })
        .catch(err => {
            console.log(err)
            toast.error("Error Occurred")
        })
    }
    const fetchUserConfig = () => {
        axios.get(config.apiUrl+"config?username="+props?.email)
        .then(res => {
            if(res?.data?.result?.length){
                let user = res?.data?.result[0]
                if(user?.access?.length && user.access.includes("edit")){
                    const newHeaders = [...utils.headers];
                    newHeaders.push({ label: 'Edit', key: "edit" })
                    setState(state => ({
                        ...state,
                        headers: newHeaders
                    }))
                 }
                setUserConfig(()=> res?.data?.result[0])
            }
           
        })
        .catch(err => {
            console.log(err)
            toast.error("Error Occurred")
        })
        .finally(() => {
            changeHandler(["loading"], [false]);
        })
    }

    const submit = () => {
        if(!props.email) {  
            toast.error("You are not logged in.")
            return
        }
        const postData = {
            status: 'Pending'
        };
        let cancel = false;
        utils.newInvoice.forEach(f => {
            if(state[f.key] === null) cancel = true;
            if (f.key === "invoiceType") {
                postData["proformaInvoice"] = state[f.key] === 'Proforma Invoice';
            } else postData[f.key] = state[f.key];
        })    
        if (cancel) {
            toast.error('All fields are mandatory', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            console.log(postData)
            return;
        }   
        postData.addedBy = props.email;
        changeHandler(["loading"], [true]);
        
        const data = new FormData() 
        data.append('invoiceFile', postData.invoice)
        data.append('invoiceData', JSON.stringify(postData))
        let url = config.apiUrl + "invoice"
        
        axios.post(
            url, 
            data, 
            {
                headers: {
                    'content-type': 'multipart/form-data',
                }
            }
        )
        .then(res => {
            console.log(res);
            toast.success('Upload Complete');
            initApp();
        })
        .catch(err => {
            console.log(err)
            toast.error("Error Occurred")
        })
        .finally(() => {
            changeHandler(["loading"], [false]);
        })
        toast.success('Uploading To Server, Wait until upload completes', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        });
    }
    const fetchANdSetStatus = () => {
         let statsUrl = config.apiUrl + `stats?stake_holder=${state.searchStakeHolder}&vendor_name=${state.searchVendor}`
        axios.get(statsUrl).then((r)=>{
            changeHandler([ "counters"], [r.data.results])
        })
        .catch(err => {
            console.log(err)
            changeHandler(["loading"], [false]);
            props.error("Can't fetch data")
        })
    }
    const initApp = () => {
        let invoiceUrl = config.apiUrl + `invoice?status=${state.selectedCounter}&stake_holder=${state.searchStakeHolder}&vendor_name=${state.searchVendor}`
        let statsUrl = config.apiUrl + `stats?stake_holder=${state.searchStakeHolder}&vendor_name=${state.searchVendor}`
        fetchANdSetStatus()
        axios.get(invoiceUrl).then((r)=>{
            changeHandler(["data",  "addShipment", "file", "receipt_id", "invoice_id","addReceipt","addTaxInvoice","addProformaInvoice", "editInvoice", "invoiceToEdit","loading"]
                , [r.data.results,  false, null,null, null, false, false, false, false, {},false])
        })
        .catch(err => {
            console.log(err)
            changeHandler(["loading"], [false]);
            props.error("Can't fetch data")
        })
        // console.log(p1)
        // const p2 = axios.get(statsUrl)
        // changeHandler(["loading"], [true]);
        // Promise.all([p1])
        // .then(([r0]) => {
        //     changeHandler(["data",  "addShipment", "file", "receipt_id", "invoice_id","addReceipt","addTaxInvoice","addProformaInvoice", "editInvoice", "invoiceToEdit","loading"]
        //         , [r0.data.results,  false, null,null, null, false, false, false, false, {},false])
        // })
        // .catch(err => {
        //     console.log(err)
        //     changeHandler(["loading"], [false]);
        //     props.error("Can't fetch data")
        // })
    }

    const updateStatus = (_id, status, oldstatus, vendor_name) => {
        if (userConfig?.access?.includes(`${oldstatus}->${status}`)) {
            axios.patch(
                config.apiUrl + "/status", 
                {
                    _id,
                    status,
                    vendor_name
                }
            )
            .then(r => {
                console.log(r)
                initApp();
            })
            .catch(e => {
                console.log(e)
                props.error("Error Occurred")
            })
            .finally(() => {
                changeHandler(["updatingStatusOf", "loading"], [null, false]);
            })
            changeHandler(["updatingStatusOf", "loading"], [_id, true]);
        }
        else props.error("Not authorized")
    }

    const updateRemark = (_id, remark) => {
        if (userConfig?.access?.includes(`Remark`)) {
            axios.patch(
                config.apiUrl + "/remark", 
                {
                    _id,
                    remark
                }
            )
            .then(r => {
                console.log(r)
                initApp();
            })
            .catch(e => {
                console.log(e)
                props.error("Error Occurred")
            })
            .finally(() => {
                changeHandler(["updatingRemarkOf", "loading"], [null, false]);
            })
            changeHandler(["updatingRemarkOf", "loading"], [_id, true]);
        }
        else props.error("Not authorized")
    }

    const editInvoice = () => {
        changeHandler(["loading"], [true]);
        if (!state.invoiceToEdit._id) return
        axios.put(config.apiUrl + "/invoice", state.invoiceToEdit)
        .then(r => {
            console.log(r)
            initApp();
            toast.success('Edit Succesful')
        })
        .catch(e => {
            console.log(e)
            props.error("Error Occurred")
        })
        .finally(() => {
            changeHandler(["loading"], [false]);
        })
    }

    const editInvoiceHandler = (keys, values) => {
        const newInvoice = {...state.invoiceToEdit};
        keys.forEach((element, index) => {
            newInvoice[element] = values[index]
        });
        setState(state => ({
            ...state,
            invoiceToEdit: newInvoice
        }))
    }
    // const submitInvoice
    const submitFile = () => {
        if (!state.file) return;
        const data = new FormData() 
        const fileData = {
            invoiceId: state.invoiceId,
            fileType: state.fileType,
        }
        data.append("file", state.file)
        data.append("fileData",JSON.stringify(fileData))
        
        let url = config.apiUrl + "uploadFile";
        axios.post(
            url, 
            data, 
            {
                headers: {
                    'content-type': 'multipart/form-data',

                }
            }
        )
        .then(res => {
            console.log(res);
            initApp();
            toast.success('Upload Succesful')
        })
        .catch(err => {
            console.log(err)
            props.error("Error Occurred")
        })
        .finally(() => {
            changeHandler(["loading"], [false]);
        })
        toast.success('Uploading To Server, Wait until upload completes', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        });
    }

    
    const downloadData = () => {
        const data = state.data.map(data => {
            return {...data, invoice: data.invoice?.Location, receipt: data.receipt?.Location, due_date: dateFormat(new Date(data.due_date), 'dd-MM-yyyy')}
        })
        const wb = xlsx.utils.book_new();
        const file_name = `Invoices-Intugine-${dateFormat(new Date(), 'dd-MM-yyyy')}.xlsx`;
        const sheet_name = "Data"
        xlsx.utils.book_append_sheet(
        wb, xlsx.utils.json_to_sheet(data),
        sheet_name
        );
        xlsx.writeFile(wb, file_name);
    }
    const checkInvoice = (e) =>{
        const checkedValue = e.target.value
        if(checkedValue==="pro")
            changeHandler(["proformaInvoice"],[true])
        else
            changeHandler(["proformaInvoice"],[false])
    }

    React.useEffect(() => {
        // call api if user logged in
        console.log("user logged in")
        changeHandler(["loading"],[true])
        if(props?.email){
            fetchUserConfig()
            initApp();
            fetchVendorList()
        }
       
    }, [state.selectedCounter,props?.loggedIn])

    React.useEffect(()=>{
        // console.log("props",props)
    },[userConfig])

    // React.useEffect(() => {
    //     const newHeaders = [...utils.headers];
    //     if (utils.authorized[props.email]?.includes("edit")) newHeaders.push({ label: 'Edit', key: "edit" })
    //     setState(state => ({
    //         ...state,
    //         headers: newHeaders
    //     }))
    // }, [props.email])

    const userNotLoggedIn = ()=> (<Grid container justify="center">
            <Grid item xs={12}>
            <h2 style={{textAlign: "center", marginTop: "40vh"}}> User Not Logged In</h2>
                </Grid>
                </Grid>)
   
    return (
        <>
        {!props.loggedIn ? userNotLoggedIn() : (
        <Grid container justify="center">
           <Grid item xs={12}>
                <Counters counters={state.counters} selectedCounter={state.selectedCounter} select={v => { changeHandler(["selectedCounter"], [v]);}}/>
            </Grid>
            <Grid item xs={12} style={{padding: "0px 30px 30px 30px",background: "#43425D", display: "flex", justifyContent: "center"}}>
                <Button style={{color: "white", borderColor: "white", margin: "10px"}} variant="outlined" onClick={_ => changeHandler(["addShipment"], [true])}>Add New Invoice</Button>
                <WhiteTextField
                    label="Search vendor"
                    variant="outlined"
                    style={{margin: "10px"}}
                    InputLabelProps={{
                        style: { color: '#fff' },
                    }}
                    InputProps={{
                        style: {
                            color: 'white'
                        }
                    }} 
                    value={state.searchVendor}
                    onChange={e => changeHandler(["searchVendor"], [e.target.value])}
                />
                <WhiteTextField
                    label="Search stake holder"
                    variant="outlined"
                    style={{margin: "10px"}}
                    InputLabelProps={{
                        style: { color: '#fff' },
                    }}
                    InputProps={{
                        style: {
                            color: 'white'
                        }
                    }} 
                    value={state.searchStakeHolder}
                    onChange={e => changeHandler(["searchStakeHolder"], [e.target.value])}
                />
                <Button onClick={initApp} style={{margin: "20px"}} variant="contained"> Search </Button>
                <CloudDownloadIcon style={{margin: "20px", color: "white"}} onClick={downloadData}/>
            </Grid>
            <Grid item xs={12}>
                <Table loading={state.loading} updatingRemarkOf={state.updatingRemarkOf} updatingStatusOf={state.updatingStatusOf} data={state.data} headers={state.headers} updateRemark={updateRemark} updateStatus={updateStatus} uploadReceipt={_id => changeHandler(["addReceipt", "receipt_id"], [true, _id])} uploadTaxInvoice={_id => changeHandler(["addTaxInvoice", "invoice_id"], [true, _id])} uploadProformaInvoice={_id => changeHandler(["addProformaInvoice", "invoice_id"], [true, _id])} editInvoice={invoice => changeHandler(["editInvoice", "invoiceToEdit"], [true, {...invoice}])}/>
            </Grid>
            <Dialog open={state.addShipment} close={_ => changeHandler(["addShipment"], [false])} fullWidth maxWidth={"lg"}>
                <DialogTitle style={{backgroundColor: "#43425D", color: "white"}}>
                    <Grid container>
                        <Grid item xs={12} style={{fontFamily: "'Montserrat', sans-serif", margin: "2px"}}>
                            Add New Invoice
                        </Grid>
                        <Grid item xs={12} style={{fontWeight: "200", fontSize: "14px",fontFamily: "'Montserrat', sans-serif", margin: "2px"}}>
                            Fill the below details to create a entry
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Grid container justify="space-evenly" style={{padding: "15px 0"}}>
                    {
                        utils.newInvoice.map(field => (
                            field.type === "file" ?
                            <Grid item xs={12} md={4} style={{padding: "30px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                
                                {field.label}
                                <input type="file" onChange={e => changeHandler([field.key], [e.target.files[0]])}/>
                                
                            </Grid>
                            : 
                                field.type=== "radio" ?
                                <Grid item xs={12} md={4} style={{padding: "30px", display: "flex", justifyContent: "space-evenly", alignItems: "center"}}>
                                    {field.label} 
                                <div>
                                    {
                                        (field.options || []).map(option => 
                                            <div><input type="radio" key={option} value={option} checked={state[field.key] === option}  onChange={e => changeHandler([field.key], [option])} /> {option}</div>
                                        )
                                    }
                                 </div>                               
                                </Grid>

                            :
                                field.type === "date" ? 
                                <Grid item xs={12} md={4} style={{padding: "30px"}}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            label={field.label}
                                            value={state[field.key]}
                                            onChange={date => changeHandler([field.key], [date])}
                                            animateYearScrolling
                                            inputVariant="outlined"
                                            format="MM/dd/yyyy"
                                            fullWidth
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            

                            :
                            <Grid item xs={12} md={4} style={{padding: "30px"}}>
                                <TextField variant="outlined" fullWidth value={state[field.key]} label={field.label} onChange={e => changeHandler([field.key], [e.target.value])} select={field.type === "select"}>
                                    {
                                        (field.options || [1]).map(option => (
                                            <MenuItem value={option}>{option}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Grid>
                        ))
                    }
                </Grid>
                <DialogActions>
                    <Grid container justify="center">
                        <Button style={{color: "red"}} onClick={_ => changeHandler(["addShipment"], [false])}>Cancel</Button>
                        {/* <Button style={{color: "blue"}} onClick={_ => changeHandler(["addShipment"], [false])}>Clear</Button> */}
                        <Button variant="contained" color="primary" style={{backgroundColor: "#28AE14"}} onClick={submit} disabled={state.loading}>{ state.loading ? <CircularProgress size="20px"/> : "Submit" }</Button>
                    </Grid>
                </DialogActions>
            </Dialog>
            <Dialog open={state.addReceipt} close={_ => changeHandler(["addReceipt"], [false])} fullWidth maxWidth={"sm"}>
                <DialogTitle style={{backgroundColor: "#43425D", color: "white"}}>
                    <Grid container>
                        <Grid item xs={12} style={{fontFamily: "'Montserrat', sans-serif", margin: "2px"}}>
                            Add New Receipt
                        </Grid>
                        <Grid item xs={12} style={{fontWeight: "200", fontSize: "14px",fontFamily: "'Montserrat', sans-serif", margin: "2px"}}>
                            Choose a file from your device
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Grid container style={{padding: "25px 25px 10px 25px"}}>
                    <Grid item xs={11}>
                        Receipt Upload <br />
                        <input type="file" onChange={e => changeHandler(["file", "fileType", "invoiceId"], [e.target.files[0],"receipt",state.receipt_id ])}/>
                    </Grid>
                </Grid>
                
                <DialogActions>
                    <Grid container justify="center">
                        <Button style={{color: "red"}} onClick={_ => changeHandler(["addReceipt"], [false])}>Cancel</Button>
                        <Button variant="contained" color="primary" style={{backgroundColor: "#28AE14"}} onClick={submitFile} disabled={state.loading}>{ state.loading ? <CircularProgress size="20px"/> : "Submit" }</Button>
                    </Grid>
                </DialogActions>
            </Dialog>
            
            {/* similar logic as above for add invoice dialogbox */}
            <Dialog open={state.addProformaInvoice} close={_ => changeHandler(["addProformaInvoice"], [false])} fullWidth maxWidth={"sm"}>
                <DialogTitle style={{backgroundColor: "#43425D", color: "white"}}>
                    <Grid container>
                        <Grid item xs={12} style={{fontFamily: "'Montserrat', sans-serif", margin: "2px"}}>
                            Add New proforma Invoice
                        </Grid>
                        <Grid item xs={12} style={{fontWeight: "200", fontSize: "14px",fontFamily: "'Montserrat', sans-serif", margin: "2px"}}>
                            Choose a file from your device
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Grid container style={{padding: "25px 25px 10px 25px"}}>
                    <Grid item xs={11}>
                        Invoice Upload <br />
                        <input type="file" onChange={e => changeHandler(["file", "fileType", "invoiceId"], [e.target.files[0],"proformaInvoice",state.invoice_id ])}/>
                    </Grid>
                </Grid>
                
                <DialogActions>
                    <Grid container justify="center">
                        <Button style={{color: "red"}} onClick={_ => changeHandler(["addProformaInvoice"], [false])}>Cancel</Button>
                        <Button variant="contained" color="primary" style={{backgroundColor: "#28AE14"}} onClick={submitFile} disabled={state.loading}>{ state.loading ? <CircularProgress size="20px"/> : "Submit" }</Button>
                    </Grid>
                </DialogActions>
            </Dialog>

            <Dialog open={state.addTaxInvoice} close={_ => changeHandler(["addTaxInvoice"], [false])} fullWidth maxWidth={"sm"}>
                <DialogTitle style={{backgroundColor: "#43425D", color: "white"}}>
                    <Grid container>
                        <Grid item xs={12} style={{fontFamily: "'Montserrat', sans-serif", margin: "2px"}}>
                            Add New Tax Invoice
                        </Grid>
                        <Grid item xs={12} style={{fontWeight: "200", fontSize: "14px",fontFamily: "'Montserrat', sans-serif", margin: "2px"}}>
                            Choose a file from your device
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Grid container style={{padding: "25px 25px 10px 25px"}}>
                    <Grid item xs={11}>
                        Invoice Upload <br />
                        <input type="file" onChange={e => changeHandler(["file", "fileType", "invoiceId"], [e.target.files[0],"invoice",state.invoice_id ])}/>
                    </Grid>
                </Grid>
                
                <DialogActions>
                    <Grid container justify="center">
                        <Button style={{color: "red"}} onClick={_ => changeHandler(["addTaxInvoice"], [false])}>Cancel</Button>
                        <Button variant="contained" color="primary" style={{backgroundColor: "#28AE14"}} onClick={submitFile} disabled={state.loading}>{ state.loading ? <CircularProgress size="20px"/> : "Submit" }</Button>
                    </Grid>
                </DialogActions>
            </Dialog>
            <Dialog open={state.editInvoice} close={_ => changeHandler(["editInvoice"], [false])} fullWidth maxWidth={"lg"}>
                <DialogTitle style={{backgroundColor: "#43425D", color: "white"}}>
                    <Grid container>
                        <Grid item xs={12} style={{fontFamily: "'Montserrat', sans-serif", margin: "2px"}}>
                            Edit Invoice
                        </Grid>
                        <Grid item xs={12} style={{fontWeight: "200", fontSize: "14px",fontFamily: "'Montserrat', sans-serif", margin: "2px"}}>
                            Editing invoice with id {state.invoiceToEdit?._id}
                        </Grid>
                        </Grid>
                </DialogTitle>
                <Grid container justify="space-evenly" style={{padding: "15px 0"}}>
                    {
                        Object.keys({...state.invoiceToEdit}).filter(k => !["_id", "invoice", "receipt", "createdAt"].includes(k)).map(key => (
                            key === "due_date" ?
                            <Grid item xs={12} md={4} style={{padding: "30px"}}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    {/* <KeyboardDatePicker
                                        clearable
                                        inputVariant="outlined"
                                        value={state.invoiceToEdit.due_date}
                                        onChange={date => editInvoiceHandler(["due_date"], [date])}
                                        maxDate={new Date()}
                                        format="MM/dd/yyyy"
                                        fullWidth
                                        label="Edit due_date"
                                    /> */}
                                    <DatePicker
                                        label="Edit due_date"
                                        value={state.invoiceToEdit.due_date}
                                        onChange={date => editInvoiceHandler(["due_date"], [date])}
                                        animateYearScrolling
                                        inputVariant="outlined"
                                        format="MM/dd/yyyy"
                                        fullWidth
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            :
                            <Grid item xs={12} md={4} style={{padding: "30px"}}>
                                <TextField fullWidth label={"Edit " + key} value={state.invoiceToEdit[key]} variant="outlined" onChange={e => editInvoiceHandler([key], [e.target.value])}/>
                            </Grid>
                        ))
                    }
                </Grid>
                <DialogActions>
                    <Grid container justify="center">
                        <Button style={{color: "red"}} onClick={_ => changeHandler(["editInvoice"], [false])}>Cancel</Button>
                        <Button variant="contained" color="primary" style={{backgroundColor: "#28AE14"}} onClick={editInvoice} disabled={state.loading}>{ state.loading ? <CircularProgress size="20px"/> : "Submit" }</Button>
                    </Grid>
                </DialogActions>
            </Dialog>
            <ToastContainer />
        </Grid>
        )}
        </>
    );
}

const mapStateToProps = (state) => ({
    email: state.email,
    loggedIn: state.loggedIn
})

export default connect(mapStateToProps)(Home);